import * as React from "react";
import { css, keyframes } from "@emotion/react";

interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  styles?: any;
  isLoading: boolean;
}

const InnerIndicatorComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> | null => {
  const { styles = {}, isLoading, ...rest } = props;
  const { exBlockStyle } = styles;
  if (!isLoading) {
    return null;
  }
  const blockStyles: any = css([blockStyle, exBlockStyle]);
  return (
    <div css={blockStyles} {...rest}>
      <div css={loaderStyle}>Loading...</div>
    </div>
  );
};
export const InnerIndicator: any = React.memo(InnerIndicatorComponent);

const zIndex = 99999;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const blockStyle = css`
  position: absolute;
  z-index: ${zIndex};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const loaderStyle = css`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border-top: 0.75rem solid rgba(255, 255, 255, 0.5);
  border-right: 0.75rem solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.75rem solid rgba(255, 255, 255, 0.5);
  // #fffだとなぜかLIFFで黒になってしまうため#fffffeにしている
  border-left: 0.75rem solid #fffffe;
  animation-name: ${spin};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-sizing: border-box;
  &:after {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
  }
`;
