import * as React from "react";
import { css } from "@emotion/react";
import { InnerIndicator } from "main/javascripts/components/indicator/InnerIndicator";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { minResolution } from "main/javascripts/styles/base/responsiveStyle";

type ButtonType = "button" | "buttonSmall";
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  buttonType?: ButtonType;
  styles?: any;
  isLoading: boolean;
}

const ButtonWithLoaderComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles = {}, buttonType, children, isLoading, ...rest } = props;
  let buttonTypeStyle;
  switch (buttonType) {
    case "buttonSmall":
      buttonTypeStyle = buttonSmallStyle;
      break;
    default:
      buttonTypeStyle = buttonStyle;
  }
  const { exBlockStyle, exButtonStyle } = styles;

  // スタイル文字列の切り替えを動的に行うとemotionでwarningが発生するため、
  // Stateによる切り替え部分はコンポーネント内部で定義する
  const loadingTextStyle = css`
    ${textStyle};
    transform: translateX(${isLoading ? "-1rem" : 0});
  `;
  const buttonWithLoaderStyles = isLoading
    ? css`
        ${buttonWithLoaderStyle};
        &:disabled {
          opacity: 1;
        }
      `
    : buttonWithLoaderStyle;

  return (
    <div css={[blockStyle, exBlockStyle]}>
      <button
        css={[buttonTypeStyle, buttonWithLoaderStyles, exButtonStyle]}
        {...rest}
      >
        <span css={loadingTextStyle}>{children}</span>
        <InnerIndicator
          isLoading={isLoading}
          styles={{ exBlockStyle: loaderBlockStyle }}
        />
      </button>
    </div>
  );
};
export const ButtonWithLoader: any = React.memo(ButtonWithLoaderComponent);

const blockStyle = css`
  padding: ${space.atom};
`;
const buttonStyle = css`
  position: relative;
  display: block;
  font-size: ${fontSize.mediumHeading};
  font-weight: ${fontWeight.regular};
  color: white;
  background-color: ${accentColor.primaryColor};
  padding: 0 ${space.atom};
  border: none;
  border-radius: ${borderRadius.normal};
  margin: 0;
  width: 100%;
  min-width: 64px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1em;
  transition: background 0.4s ease, opacity 0.4s ease;
  &:hover {
    background-color: ${accentColor.primaryDarkColor};
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${accentColor.primaryColor};
    }
  }
  ${minResolution("scale2x")}: {
    font-weight: ${fontWeight.medium};
  }
`;
const buttonSmallStyle = css`
  display: block;
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.regular};
  color: white;
  background-color: ${accentColor.primaryColor};
  padding: 0 ${space.atom0_5x};
  border: none;
  border-radius: ${borderRadius.normal};
  margin: 0;
  width: 100%;
  min-width: 64px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1em;
  transition: background 0.4s ease, opacity 0.4s ease;
  &:hover {
    background-color: ${accentColor.primaryDarkColor};
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${accentColor.primaryColor};
    }
  }
  ${minResolution("scale2x")} {
    font-weight: ${fontWeight.medium};
  }
`;
const loaderBlockStyle = css`
  left: auto;
  right: 0.5rem;
  transform: translate(0, -50%);
`;
const buttonWithLoaderStyle = css`
  padding: 0 1.5rem;
  position: relative;
  min-width: 80px;
`;
const textStyle = css`
  display: block;
  transition: transform 0.4s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
